body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

@keyframes rotating {
  from{
    transform: rotate(-90deg);
    padding-right: 6px;
    padding-top: 0;
  }
  to{
    transform: rotate(0deg);
    padding-right: 0;
    padding-top: 6px;
    box-sizing: border-box;
  }
}

.rotating {
  animation: rotating 3s ease forwards;
  animation-delay: 3s;
}
